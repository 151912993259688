<template>
    <editor-page class="web-editor" title="Web Editor" v-if="cmsPage" no-padding v-hotkey="shortcuts">
        <template v-if="!simulatorVisible && !loading">
            <b-navbar class="editor-menu bg-white">
                <b-nav>
                    <b-dropdown :text="'File|Fichier'|tr" variant="white" no-caret>
                        <b-dropdown-item @click="save()">
                            <i class="fas fa-save"/>
                            <span v-tr>Save|Enregistrer</span>
                            <span class="shortcut">(Ctrl + S)</span>
                        </b-dropdown-item>
                        <b-dropdown-divider/>
                        <b-dropdown-item @click="exit">
                            <i class="fas fa-right-from-bracket fa-flip-horizontal"/>
                            <span v-tr>Exit|Quitter</span>
                        </b-dropdown-item>
                    </b-dropdown>

                    <b-dropdown :text="'Edit|Edition'|tr" variant="white" no-caret>
                        <b-dropdown-item @click="setAddBlockMode">
                            <i class="fas fa-rectangle-history-circle-plus"/>
                            <span v-tr>Add Section|Ajouter une section</span>
                            <span class="shortcut">(Ctrl + Enter)</span>
                        </b-dropdown-item>
                        <b-dropdown-divider/>
                        <b-dropdown-item @click="autoTranslate">
                            <i class="fas fa-language"/>
                            <span v-tr>Translate|Traduire</span>
                            <span class="shortcut text-uppercase">{{ displayLanguage }} -> {{ translateLanguage }}</span>
                        </b-dropdown-item>
                    </b-dropdown>

                    <b-dropdown :text="'View|Affichage'|tr" variant="white" no-caret>
                        <b-dropdown-item @click="previewNavigation = !previewNavigation">
                            <i :class="previewNavigation ? 'fas fa-eye' : 'fas fa-eye-slash'"/>
                            <span v-tr>Display Navigation|Afficher la navigation</span>
                        </b-dropdown-item>
                        <b-dropdown-divider/>
                        <b-dropdown-item :to="previewUrl" target="_blank">
                            <i class="fas fa-external-link"/>
                            <span v-tr>Open Page in another Tab|Ouvrir la page dans un autre onglet</span>
                        </b-dropdown-item>
                        <b-dropdown-divider/>
                        <b-dropdown-header>
                            <span v-tr>Simulator|Simulateur</span>
                        </b-dropdown-header>
                        <b-dropdown-item @click="setSimulatorMode(`mobile`)">
                            <i class="fas fa-mobile-iphone"/>
                            <span v-tr>Mobile Preview|Prévisualiser sur mobile</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="setSimulatorMode(`tablet`)">
                            <i class="fas fa-tablet-screen"/>
                            <span v-tr>Tablet Preview|Prévisualiser sur tablette</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="setSimulatorMode(`desktop`)">
                            <i class="fas fa-desktop"/>
                            <span v-tr>Desktop Preview|Prévisualiser sur ordinateur</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="setSimulatorMode(`kiosk`)">
                            <i class="fak fa-kiosk"/>
                            <span v-tr>Kiosk Preview|Prévisualiser sur kiosque</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="setSimulatorMode(`fullscreen`)">
                            <i class="fas fa-maximize"/>
                            <span v-tr>Fullscreen Preview|Prévisualiser en plein écran</span>
                        </b-dropdown-item>
                    </b-dropdown>

                    <b-btn class="ml-2" @click="save()" variant="light-primary">
                        <i class="fas fa-save mr-1"/>
                        <span v-tr>Save|Enregistrer</span>
                    </b-btn>
                </b-nav>
                <b-nav class="mx-auto">
                    <span class="bg-light rounded d-inline-flex align-items-center">
                        <b-dropdown id="website-menu" variant="light" no-caret>
                            <template #button-content>
                                <b-img width="24" height="24" style="margin: -4px 5px -2px -2px;" v-if="cmsWebsite.design.favicon.src" :src="cmsWebsite.design.favicon.src"/>
                                <i v-else class="fas fa-globe mr-2"/>
                                <span>{{ website.name|tr }}</span>
                                <i class="fas fa-caret-right ml-2"/>
                            </template>
                            <b-dropdown-header><span v-tr>Website|Site internet</span></b-dropdown-header>
                            <b-dropdown-item @click="mode = MODES.THEME">
                                <i class="fas fa-pen-paintbrush"/>
                                <span v-tr>Site Design|Style du site</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="mode = MODES.NAVIGATION">
                                <i class="fas fa-browser"/>
                                <span v-tr>Navigation Links|Navigation et liens</span>
                            </b-dropdown-item>
                            <b-dropdown-divider/>
<!--                            <b-dropdown-header><span v-tr>Structure & Navigation|Structure et navigation</span></b-dropdown-header>-->
                            <b-dropdown-item @click="websiteSettingsVisible = true">
                                <i class="fas fa-cog"/>
                                <span v-tr>Website Settings & SEO|Paramètres du site et SEO</span>
                            </b-dropdown-item>
                        </b-dropdown>

                        <b-popover variant="primary" v-if="!cmsWebsite.settings.init && cmsWebsite.design.init && cmsPage.blocks.length > 0" target="#website-menu" placement="bottom" show>
                            <template #title>
                                <virtual-assistant-avatar/>
                                <span v-tr>Change Settings|Modifier les paramètres</span>
                            </template>
                            <div class="f-500 f-12" v-tr>Change site title, favicon, SEO, copyright and terms and conditions|Changez le titre du site, le favicon, le SEO, les copyright et les termes et conditions</div>
                            <div class="text-right mt-2">
                                <b-btn variant="white" @click="cmsWebsite.settings.init = true">
                                    <span v-tr>Dismiss|Plus tard</span>
                                </b-btn>
<!--                                <b-btn variant="primary" class="ml-2" @click="websiteSettingsVisible = true">-->
<!--                                    <span v-tr>Open Settings|Ouvrir les paramètres</span>-->
<!--                                </b-btn>-->
                            </div>
                        </b-popover>

                        <b-dropdown variant="light" no-caret>
                            <template #button-content>
                                <i class="fas mr-2" :class="cmsPage.glyph || 'fa-file-lines'"/>
                                <span>{{ cmsPage.name|tr }}</span>
                                <i class="fas fa-caret-down ml-2"/>
                            </template>
                            <b-dropdown-header><span v-tr>Current Page|Page actuelle</span></b-dropdown-header>
                            <b-dropdown-item @click="editPageSettings = true">
                                <i class="fas fa-cog"/>
                                <span v-tr>Page Settings|Paramètres de la page</span>
                            </b-dropdown-item>
                            <b-dropdown-divider/>
                            <e-button-confirm @confirm="duplicatePage" dropdown-item :confirm-text="'You will duplicate this page|Vous allez dupliquer cette page'|tr">
                                <i class="fas fa-copy mr-1"/>
                                <span v-tr>Duplicate Page|Dupliquer la page</span>
                            </e-button-confirm>
                            <e-button-confirm @confirm="deletePage" dropdown-item dropdown-class="text-danger" :confirm-text="'Are you sure you want to delete this page? | Êtes-vous sûr de vouloir supprimer cette page ?'|tr">
                                <i class="fas fa-trash"/>
                                <span v-tr>Delete Page|Supprimer la page</span>
                            </e-button-confirm>
                            <b-dropdown-divider/>
                            <b-dropdown-header><span v-tr>Other Pages|Autres pages</span></b-dropdown-header>

                            <b-dropdown-item @click="showIframeDialog()">
                                <i class="fas fa-file-plus"/>
                                <span v-tr>Create a new page|Créer une nouvelle page</span>
                            </b-dropdown-item>
                            <b-dropdown-divider/>
                            <b-dropdown-item v-for="page in cmsPages" @click="openPage(page)" :key="`page-list-${page.id}`">
                                <i class="fas" :class="page.glyph ? page.glyph : 'fa-file'"/>
                                <span>{{ page.name|tr }}</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </span>

                    <b-dropdown class="ml-2" variant="light" no-caret>
                        <template #button-content>
                            <i class="fas fa-plus"/>
                        </template>

                        <b-dropdown-item @click="showIframeDialog()">
                            <i class="fas fa-file-plus"/>
                            <span v-tr>Create a new page|Créer une nouvelle page</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="setAddBlockMode">
                            <i class="fas fa-rectangle-history-circle-plus"/>
                            <span v-tr>Add Section|Ajouter une section</span>
                            <span class="shortcut">(Ctrl + Enter)</span>
                        </b-dropdown-item>
                    </b-dropdown>

<!--                    <b-btn class="ml-2" variant="light" @click="showIframeDialog()" :title="'Add a page|Ajouter une page'|tr" v-b-tooltip>-->
<!--                        <i class="fas fa-plus"/>-->
<!--                    </b-btn>-->

                    <btn-language class="ml-2" variant="light" @input="$store.state.previewLanguage = $event" :value="displayLanguage"/>
                </b-nav>

                <b-dropdown class="ml-2" variant="light" right>
                    <template #button-content>
                        <template v-if="previewMode === `mobile`">
                            <i class="fas fa-mobile-iphone mr-1"/>
                            <span v-tr>Mobile</span>
                        </template>
                        <template v-else-if="previewMode === `tablet`">
                            <i  class="fas fa-tablet-screen mr-1"/>
                            <span v-tr>Tablet|Tablette</span>
                        </template>
                        <template v-else-if="previewMode === `desktop`">
                            <i  class="fas fa-desktop mr-1"/>
                            <span v-tr>Desktop|Ordinateur</span>
                        </template>
                        <template v-else-if="previewMode === `kiosk`">
                            <i  class="fak fa-kiosk mr-1"/>
                            <span v-tr>Kiosk|Kiosque</span>
                        </template>
                    </template>
                    <b-dropdown-item @click="setPreviewMode(`desktop`)" :class="{'active': previewMode === `desktop`}">
                        <i class="fas fa-desktop"/>
                        <span v-tr>Desktop Display|Affichage ordinateur</span>
                    </b-dropdown-item>
                    <b-dropdown-divider/>
                    <b-dropdown-item @click="setPreviewMode(`mobile`)" :class="{'active': previewMode === `mobile`}">
                        <i class="fas fa-mobile-iphone"/>
                        <span v-tr>Mobile Display|Affichage mobile</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="setPreviewMode(`tablet`)" :class="{'active': previewMode === `tablet`}">
                        <i class="fas fa-tablet-screen"/>
                        <span v-tr>Tablet Display|Affichage tablette</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="setPreviewMode(`kiosk`)" :class="{'active': previewMode === `kiosk`}">
                        <i class="fak fa-kiosk"/>
                        <span v-tr>Kiosk Display|Affichage kiosque</span>
                    </b-dropdown-item>
                </b-dropdown>

                <b-btn class="ml-2" variant="light" :href="previewUrl" target="_blank" :title="'Open in external window|Ouvrir dans une autre fenêtre'|tr">
                    <i class="fas fa-external-link mr-1"/>
                    <span v-tr>Open|Ouvrir</span>
                </b-btn>
            </b-navbar>

            <editor-submenu :disabled="!blockSelected"/>

            <div class="editor-container horizontal">
                <div class="padding-bar vertical">
                    <padding-rule v-if="blockSelected" direction="top" :max="500" v-model="blockSelected.padding.top" @input="blockSelected.padding.bottom = $event"/>
                    <!--                <padding-rule v-if="blockSelected" class="mt-auto" direction="bottom" v-model="blockSelected.padding.bottom"/>-->
                </div>
                <template v-if="cmsPage.blocks.length === 0">
                    <e-empty-placeholder title="This page looks empty|Cette page a l'air un peu vide">
                        <b-btn id="add-section" variant="primary" @click="setAddBlockMode">
                            <span v-tr>Add your First Section|Ajouter votre première section</span>
                        </b-btn>
                    </e-empty-placeholder>

                    <b-popover variant="primary" v-if="cmsPage.blocks.length === 0" target="#add-section" placement="bottom" show>
                        <template #title>
                            <virtual-assistant-avatar/>
                            <span v-tr>This page looks empty|Cette page est vide</span>
                        </template>
                        <div class="f-500 f-12" v-tr>
                            Start by adding content to your page by clicking on the button above.|Commencez en ajoutant du contenu dans votre page en cliquant sur le bouton ci-dessus.
                        </div>
                        <!--            <div class="text-right mt-2">-->
                        <!--                <b-btn variant="primary" class="ml-2" @click="$emit('add-block')">-->
                        <!--                    <span v-tr>Add First Section|Ajouter une section</span>-->
                        <!--                </b-btn>-->
                        <!--            </div>-->
                    </b-popover>
                </template>
                <preview-responsive-container v-else>
                    <template #above>
                        <div class="padding-bar horizontal">
                            <cms-container v-if="blockSelected" :mode="blockSelected.container" horizontal style="background-color: transparent">
                                <i @click="toggleBlockContainer" class="toggle-container left fas"
                                   :class="{ 'fa-chevron-right': blockSelected.container === 'fill', 'fa-chevron-left': blockSelected.container === 'contained', 'fa-chevrons-left': blockSelected.container === 'extended' }"/>
                                <padding-rule direction="left" v-model="blockSelected.padding.left" @input="blockSelected.padding.right = $event"
                                              :max="isMobileLayoutOrPreview ? 150 : website.body.design.style.maxWidth / 2"/>
                                <!--                            <padding-rule direction="right" v-model="blockSelected.padding.right" class="ml-auto"/>-->
                            </cms-container>
                        </div>
                    </template>
                    <cms-block-builder :blocks="cmsPage.blocks" v-model="blockSelected" :no-navigation="!isNavigationVisible" :block-selection="blockSelection"
                                       @block-selected="selectBlock" editable/>
                </preview-responsive-container>
            </div>
        </template>
        <template v-else-if="simulatorVisible">
            <b-navbar class="editor-menu bg-white">
                <h3 class="m-0" v-tr>Device Simulator|Simuler un appareil</h3>
                <b-btn class="ml-auto" variant="light-primary" @click="leaveSimulatorMode()">
                    <i class="fas fa-eye-slash mr-1"/>
                    <span v-tr>Quit Simulator|Quitter le simulateur</span>
                </b-btn>
            </b-navbar>
            <e-tv-preview view="portrait" iframe-mode v-if="simulatorMode === 'kiosk'" style="margin-top: -55px; width: 1080px; height: calc(100vh - 70px);">
                <template #header>
                    <i class="fas fa-globe mr-1"/>
                    {{ website.domain }}
                </template>
                <iframe :src="`${previewUrl}?display=kiosk&embed`" style="width: 1080px; height: calc(100vh - 70px)"/>
            </e-tv-preview>
            <e-responsive-preview v-else iframe-mode :default-view="simulatorMode" :class="{'mt-2 mt-lg-4': simulatorMode !== 'desktop'}">
                <template #header>
                    <i class="fas fa-globe mr-1"/>
                    {{ website.domain }}
                </template>
                <iframe :src="`${previewUrl}?embed`"/>
            </e-responsive-preview>
        </template>

        <b-modal v-model="editPageSettings" :title="'Page Settings|Paramètres de la page'|tr" body-bg-variant="white" :ok-title="'Save|Enregistrer'|tr" @ok="onPageEdited()">
            <page-settings-modal v-model="cmsPage"/>
        </b-modal>

        <template v-if="!simulatorVisible && !loading" #sidebar-right>
            <properties-page v-model="cmsPage" @add-block="setAddBlockMode" @block-selected="selectBlock" @edit-theme="mode = MODES.THEME"
                             @edit-menu="selectBlock(cmsWebsite.menu.block)" @edit-footer="selectBlock(cmsWebsite.footer.block)" :show-navigation="previewNavigation"/>

            <template v-if="mode === MODES.BLOCK">
                <!-- When working with children blocks, there are  -->
<!--                <properties-block-selector v-model="blockSelected" :count="cmsPage.blocks.length" :is-child="isBlockSelectedChild" :block-selection="blockSelection"-->
<!--                                           :readonly="isBlockSelectedChild && blockSelected.childrenReadonly" :no-slide="isBlockSelectedChild || blockSelection.length === 1"-->
<!--                                           @block-selected="selectBlock" @close="selectParentBlock" @move-up="moveBlockUp" @move-down="moveBlockDown" @duplicate="duplicateBlock" @delete="deleteBlock"/>-->

                <properties-block-selector v-for="(block, index) in blockSelection" :key="`block-props-${block.id}`" v-model="blockSelection[index]" :count="cmsPage.blocks.length" :is-child="index > 0" :no-slide="index > 0 && blockSelection.length > 1" :block-selection="blockSelection" :readonly="isBlockSelectedChild && blockSelected.childrenReadonly"
                                           @block-selected="selectBlock" @close="selectParentBlock" @move-up="moveBlockUp" @move-down="moveBlockDown" @duplicate="duplicateBlock" @delete="deleteBlock"  @edit-navigation="mode = MODES.NAVIGATION"/>
            </template>
            <properties-website-style v-if="cmsWebsite.design.colorSchemes" :visible="mode === MODES.THEME" v-model="cmsWebsite" @close="setWebsiteEdited" @edit-website-settings="websiteSettingsVisible = true"/>
            <properties-website-navigation :visible="mode === MODES.NAVIGATION" v-model="cmsWebsite" @close="setWebsiteEdited"/>
            <properties-new-block :visible="mode === MODES.ADD_BLOCK" @create-block="createBlock" @close="resetMode"/>
        </template>

        <e-iframe-dialog v-model="pageCreationVisible" :item-id="cmsWebsite.id" :dealer-id="website.dealerid" preset="cms-page-creation" @created="onPageCreated"/>
        <e-iframe-dialog v-model="pageDuplicateVisible" :item-id="cmsWebsite.id" :dealer-id="website.dealerid" :query-params="`duplicateId=${cmsPage.id}`" preset="cms-page-creation" @created="onPageDuplicate"/>
        <e-iframe-dialog v-model="websiteSettingsVisible" :item-id="cmsWebsite.id" preset="cms-website-settings" @saved="websiteSettingsSaved"/>
    </editor-page>
</template>

<script>
import BtnLanguage from "@/components/btn-language.vue";
import PageSettingsModal from "@/components/editor/page-settings-modal.vue";
import PropertiesBlockSelector from "@/components/editor/properties/blocks/properties-block-selector.vue";
import EditorSubmenu from "@/components/editor/editor-submenu.vue";
import PropertiesComponentNavigationLinks from "@/components/editor/properties/components/properties-component-navigation-links.vue";
import PaddingRule from "@/components/editor/properties/padding-rule.vue";
import PropertiesWebsiteNavigation from "@/components/editor/properties/properties-website-navigation.vue";
import PropertiesWebsiteStyle from "@/components/editor/properties/website/properties-website-style.vue";
import CmsBlockBuilder from "@/components/editor/rendering/blocks/cms-block-builder.vue";
import PropertiesNewBlock from "@/components/editor/properties/properties-new-block.vue";
import PropertiesPage from "@/components/editor/properties/properties-page.vue";
import CmsContainer from "@/components/editor/rendering/cms-container.vue";
import VirtualAssistantAvatar from "@/components/virtual-assistant-avatar.vue";
import CmsBlock from "@/helpers/editor/blocks/CmsBlock.js";
import CmsBlockFooter from "@/helpers/editor/blocks/CmsBlockFooter.js";
import CmsBlockMenu from "@/helpers/editor/blocks/CmsBlockMenu.js";
import EditorPage from "@/layout/editor-page.vue";
import PreviewResponsiveContainer from "@/layout/preview-responsive-container.vue";
import EButtonConfirm from "../../vue-components/components/e-button-confirm.vue";
import EEmptyPlaceholder from "../../vue-components/components/e-empty-placeholder.vue";
import EIframeDialog from "../../vue-components/components/e-iframe-dialog.vue";
import EResponsivePreview from "../../vue-components/components/e-responsive-preview.vue";
import ETvPreview from "../../vue-components/components/e-tv-preview.vue";
import Network from "../../vue-components/helpers/Network.js";
// import EResponsivePreview from "../../vue-components/components/e-responsive-preview.vue";

// web-editor #hot-reload-debug
export default {
    name: `web-editor`,
    components: {
        ETvPreview,
        PropertiesComponentNavigationLinks,
        VirtualAssistantAvatar,
        PropertiesWebsiteNavigation,
        PropertiesWebsiteStyle,
        EEmptyPlaceholder,
        BtnLanguage,
        PageSettingsModal,
        EResponsivePreview,
        EIframeDialog,
        EditorSubmenu,
        CmsContainer,
        PaddingRule,
        PropertiesBlockSelector,
        EButtonConfirm,
        PropertiesPage,
        PropertiesNewBlock,
        CmsBlockBuilder,
        PreviewResponsiveContainer,
        // EResponsivePreview,
        EditorPage
    },
    data() {
        return {
            loading: false,
            MODES: {
                STRUCT: `struct`,
                ADD_BLOCK: `add-block`,
                BLOCK: `block`,
                NAVIGATION: `navigation`,
                THEME: `theme`
            },
            mode: `struct`,
            blockSelection: [], // hierarchy of blocks selected from parent to child
            websiteEdited: false,
            previewNavigation: true,
            pageCreationVisible: false,
            pageDuplicateVisible: false,
            websiteSettingsVisible: false,
            editPageSettings: false,
            simulatorVisible: false,
            simulatorMode: `mobile`,
            duplicateId: null
        }
    },
    computed: {
        translateLanguage() {
            return this.displayLanguage === `fr` ? `en` : `fr`;
        },
        previewUrl() {
            if (this.isLocal()) {
                return `${window.env.VUE_APP_URLS__CMS}/cms/preview/website/${this.website.id}/${this.cmsPage.id}`
            } else {
                return `https://${this.website.domain}/${this.tr(this.cmsPage.permalink)}`
            }
        },
        isBlockSelectionEmpty() {
            return this.blockSelection && this.blockSelection.length > 1;
        },
        isBlockSelectedChild() {
            return this.blockSelection && this.blockSelection.length > 1;
        },
        blockSelected: {
            get() {
                if (this.blockSelection.length > 0) {
                    return this.blockSelection[this.blockSelection.length - 1];
                } else {
                    return null;
                }
            },
            set(value) {
                this.selectBlock(value);
            }
        },
        blockSelectedParent() {
            if (this.blockSelection.length > 1) {
                return this.blockSelection[this.blockSelection.length - 2];
            } else {
                return null;
            }
        },
        blockSelectedRoot() {
            if (this.blockSelection.length > 0) {
                return this.blockSelection[0];
            } else {
                return null;
            }
        },
        shortcuts() {
            return {
                "ctrl+s": this.save,
                "ctrl+enter": this.setAddBlockMode,
                "ctrl+shift+up": this.moveBlockUp,
                "ctrl+shift+down": this.moveBlockDown,
                "ctrl+d": this.duplicateBlock,
                "ctrl+delete": this.deleteBlock
                // delete: this.delete
            }
        },
        blockIndex() {
            return this.cmsPage.blocks.indexOf(this.blockSelected);
        },
        isNavigationVisible() {
            return this.previewNavigation || this.mode === this.MODES.NAVIGATION;
        }
    },
    async activated() {
        await this.init();
    },
    async mounted() {
        await this.init();

        // Reset state for kiosk preview
        this.$store.state.isKioskDevice = false;
        window.isKioskDevice = false;
        localStorage.removeItem(`kiosk`);
    },
    methods: {
        async init() {
            if (this.loading) {
                return;
            }

            this.loading = true;
            this.showLoading();

            Network.get(`/api/page/${this.$route.params.pageId}`)
                .then(resp => {
                    this.$store.commit(`setPage`, {page: resp.data, route: this.$route});

                    if (!this.cmsWebsite.menu.block) {
                        // console.warn(`Tmp always removing previous header`)
                        let menu = new CmsBlockMenu();
                        menu.logo.src = this.cmsWebsite.design.logo.src;
                        const darkTheme = this.cmsWebsite.getColorScheme(`dark`);
                        if (darkTheme) {
                            menu.colorScheme = darkTheme;
                            menu.logo.filter = `recolor-white`;
                        }
                        this.$set(this.cmsWebsite.menu, `block`, menu);
                    }
                    if (!this.cmsWebsite.footer.block) {
                        // console.warn(`Tmp always removing previous footer`)
                        let footer = new CmsBlockFooter();
                        footer.logo.src = this.cmsWebsite.design.logo.src;
                        const darkTheme = this.cmsWebsite.getColorScheme(`dark`);
                        if (darkTheme) {
                            footer.colorScheme = darkTheme;
                            footer.logo.filter = `recolor-white`;
                        }
                        this.$set(this.cmsWebsite.footer, `block`, footer);
                    }
                    this.hideLoading();
                    this.loading = false;
                })
                .catch(_ => {
                    this.loading = false;
                })
        },
        setPreviewMode(previewMode) {
            this.previewMode = previewMode;
            this.$store.commit(`updateDisplayMode`);
            this.cmsWebsite.applyStyle();
        },
        setSimulatorMode(simulatorMode = `mobile`) {
            this.simulatorMode = simulatorMode;
            this.save(() => {
                this.simulatorVisible = !this.simulatorVisible;
            });
        },
        leaveSimulatorMode() {
            this.simulatorVisible = false;
            localStorage.removeItem(`kiosk`);
        },
        selectBlock(block) {
            if (block) {
                if (this.blockSelected !== block) {
                    this.updateBlockSelection(block);
                    document.getElementById(block.id)?.scrollIntoView();
                }
                this.mode = this.MODES.BLOCK;
            } else {
                this.updateBlockSelection(null);
            }
        },
        selectParentBlock() {
            this.selectBlock(this.blockSelectedParent);
        },
        unselectBlock() {
            if (this.blockSelected) {
                this.blockSelected.applyChanges(this.displayLanguage);
            }
            this.blockSelected = null;
            this.updateBlockSelection();
            this.resetMode();
        },
        updateBlockSelection(blockSelected) {
            if (!blockSelected) {
                this.blockSelection = [];
                return;
            }

            if ([`menu`, `footer`].includes(blockSelected.type)) {
                this.blockSelection = [blockSelected];
                return;
            }

            let blockSelection = [];
            for (let block of this.cmsPage.blocks) {
                if (block.checkSelection(blockSelection, blockSelected)) {
                    break;
                }
            }
            this.blockSelection = blockSelection.reverse();
        },
        createBlock(preset) {
            const block = new preset();
            block.initNewBlock(this.cmsWebsite);
            if (this.blockSelected) {
                this.cmsPage.insertBlock(block, this.blockIndex);
            } else {
                this.cmsPage.addBlock(block);
            }
            this.$nextTick(_ => {
                this.selectBlock(block);
            })
        },
        toggleBlockContainer() {
            if (this.blockSelected.container === CmsBlock.CONTAINERS.CONTAINED) {
                this.blockSelected.container = CmsBlock.CONTAINERS.EXTENDED;
            } else if (this.blockSelected.container === CmsBlock.CONTAINERS.EXTENDED) {
                this.blockSelected.container = CmsBlock.CONTAINERS.FILL_WIDTH;
            } else {
                this.blockSelected.container = CmsBlock.CONTAINERS.CONTAINED;
            }
        },
        resetMode() {
            this.mode = this.MODES.STRUCT;
        },
        moveBlockUp() {
            if (!this.blockSelected)
                return;

            this.cmsPage.swapBlock(this.blockSelected, -1);
        },
        moveBlockDown() {
            if (!this.blockSelected)
                return;

            this.cmsPage.swapBlock(this.blockSelected, 1);
        },
        duplicateBlock() {
            if (!this.blockSelected)
                return;

            if (this.isBlockSelectedChild) {
                if (this.blockSelected.childrenReadonly) {
                    this.showToast(`Can't duplicate this section|Cette section ne peut pas être dupliquée`, 3000);
                    return;
                }
                let copy = this.blockSelectedParent.duplicateChild(this.blockSelected);
                this.$nextTick(_ => {
                    this.selectBlock(copy);
                })
            } else {
                let copy = this.blockSelected.duplicate();
                this.cmsPage.insertBlock(copy, this.blockIndex);
                this.$nextTick(_ => {
                    this.selectBlock(copy);
                })
            }
            this.showToast(`Duplicated|Dupliqué`, 3000);
        },
        deleteBlock() {
            if (this.isBlockSelectedChild) {
                if (this.blockSelected.childrenReadonly) {
                    this.showToast(`Can't delete this section|Cette section ne peut pas être supprimée`, 3000);
                    return;
                }
                this.blockSelectedParent.deleteChild(this.blockSelected)
                this.selectBlock(this.blockSelectedParent);
            } else {
                this.cmsPage.deleteBlock(this.blockSelected);
                this.unselectBlock();
            }
        },
        showIframeDialog() {
            this.pageCreationVisible = true;
        },
        setAddBlockMode() {
            this.mode = this.MODES.ADD_BLOCK;
        },
        onPageEdited() {
            this.save(() => {
                this.$store.dispatch(`refreshWebsite`);
            })
        },
        duplicatePage() {
            this.pageDuplicateVisible = true;
        },
        deletePage() {
            this.cmsPage.deleted = true;
            this.save(() => {
                window.location.href = `${window.env.VUE_APP_URLS__EYEINTELLIGENCE}/#/cms/dashboard`;
            })
        },
        async save(callback) {
            try {
                this.showLoading();

                // if (this.websiteEdited) {
                const websiteSave = this.cmsWebsite.toJSON();
                await Network.post(`/api/websites/edit`, websiteSave);
                // }

                const pageSave = this.cmsPage.toJSON();
                await Network.post(`/api/pages/edit`, pageSave);
                this.hideLoading();
                this.showToast(`Page Saved|Page enregistrée`, undefined, `b-toaster-bottom-right`);
                if (callback && typeof callback === `function`) {
                    callback();
                }
            } catch (e) {
                console.error(e);
                this.showErrorMessage(`Couldn't Save|Impossible d'enregistrer`);
            }
        },
        setWebsiteEdited() {
            this.websiteEdited = true;
            if (this.blockSelected) {
                this.mode = this.MODES.BLOCK;
            } else {
                this.mode = this.MODES.STRUCT;
            }
        },
        exit() {
            this.showConfirmationMessage(`Save and Exit|Enregistrer et quitter?`, `We will save your modifications before returning to Eye-Intelligence.|Nous allons enregistrer vos modifications avant de retourner sur Eye-Intelligence`)
                .then(result => {
                    if (result) {
                        this.save(() => {
                            window.location.href = `${window.env.VUE_APP_URLS__EYEINTELLIGENCE}/#/cms/dashboard`;
                        })
                    }
                });
        },
        onPageCreated(page) {
            this.showSavedMessage(`Page Created|Page créé`)
            this.$store.dispatch(`refreshWebsite`);
            this.openPage(page);
        },
        onPageDuplicate(page) {
            this.showSavedMessage(`Page Duplicated|Page dupliquée`)
            this.$store.dispatch(`refreshWebsite`);
            this.openPage(page);
        },
        openPage(page) {
            this.unselectBlock();
            this.$router.push(`/cms/editor/website/${this.website.id}/${page.id}`);
            this.init();
        },
        async autoTranslate() {
            this.showLoading(`Translating...|Traduction en cours...`);
            const displayLanguage = this.displayLanguage;
            const translateLanguage = this.translateLanguage;
            await this.cmsPage.translate(displayLanguage, translateLanguage);
            this.showSavedMessage(`Page Translated|Page traduite`);

            // Like this we can see the translation block by block
            this.$store.state.previewLanguage = this.translateLanguage;
            // this.$forceUpdate();
        },
        websiteSettingsSaved() {
            this.showSavedMessage(`Saved|Enregistré`, `Website Settings have been updated|Les paramètres du site ont été modifiés`);
            this.$store.dispatch(`re`)
        }
    }
}
</script>

<style lang="scss">
@import "../assets/style/editor/editor";

.web-editor {
    --accent-color: #005fe5;
    height: 100vh;
    overflow: hidden;

    .editor-menu {
        padding: 10px;

        .nav {
            align-items: center;
        }
    }

    .preview-responsive-container {
        width: 100%;
        height: 100%;
        min-height: auto !important;
        display: flex;
        flex-direction: column;

        .overflow {
            width: 100%;
            height: 100%;
            overflow: hidden auto;
            scroll-behavior: smooth;
        }
    }
}

.responsive-preview.view-desktop iframe {
    height: 100% !important;
    max-height: 100% !important;
}

.editor-container {
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;

    &.horizontal {

    }

    &.vertical {
        flex-direction: column;
    }
}

.padding-bar {
    position: sticky;
    background: var(--light-color);
    z-index: 1;

    &.horizontal {
        top: 0;
        border-bottom: 1px solid #ccc;
        height: 26px;

        //&:after {
        //    position: absolute;
        //    content: "";
        //    display: block;
        //    left: 0;
        //    right: 0;
        //    border-bottom: 1px solid #ccc;
        //}

        .cms-container {
            display: flex;
            height: 26px;
            align-items: center;
        }

        .toggle-container {
            width: 30px;
            opacity: 0.5;

            &.fa-chevron-left, &.fa-chevrons-left {
                margin-left: -33px;
            }

            &:hover {
                opacity: 1;
            }
        }
    }

    &.vertical {
        width: 21px;
        border-top: 26px solid #ddd;
        border-right: 1px solid #ccc;
        display: flex;
        flex-direction: column;
        padding-top: 3px;
        justify-content: center;
    }
}
</style>
